.p-sidebar-content {
  padding: 0 !important;
}

.sidebar-container {
  padding: 10px;
}

.p-sidebar-header {
  display: none !important;
}

.p-menu {
  width: 100%;
}

.p-menubar-button {
  display: none !important;
}

.p-card {
  padding: 0 !important;
  overflow: hidden;
}

.p-card-body {
  padding: 0 !important;
}

.p-card-content {
  padding: 0 !important;
}

.p-dropdown {
  display: flex;
  align-items: center;
  border: none;
  background: none !important;
}

.p-inputtext {
  box-shadow: none !important;
  background: none;
}

#stats-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

#stats-chart-orders {
  grid-area: 3 / 1 / 5 / 3;
}

#stats-chart-timeline {
  grid-area: 1 / 1 / 3 / 5;
}

#stats-card-donations {
  grid-area: 1 / 5 / 2 / 7;
}

#stats-card-gifts {
  grid-area: 2 / 5 / 3 / 7;
}

#stats-card-orders {
  grid-area: 3 / 3 / 5 / 7;
}
@media screen and (max-width: 1250px) {
  #stats-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  #stats-chart-timeline {
    grid-area: 1 / 1 / 2 / 2;
  }
  #stats-chart-orders {
    grid-area: 2 / 1 / 4 / 2;
  }
  #stats-card-donations {
    grid-area: 1 / 2 / 2 / 3;
  }
  #stats-card-gifts {
    grid-area: 1 / 3 / 2 / 4;
  }
  #stats-card-orders {
    grid-area: 2 / 2 / 4 / 4;
  }
}
@media screen and (max-width: 968px) {
  #stats-grid {
    display: flex;
    flex-direction: column;
  }
  #stats-grid > div {
    width: 100%;
  }
}

.v-div:before {
  content: "";
  height: 100%;
  padding: 0;
  margin: 0;
  width: 2px;
  border-left: 1px solid #e0e0e0;
}
